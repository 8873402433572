import React, { useContext } from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  visuallyHidden,
  zIndexLayers,
  labelStyles,
  fullLabelStyles,
  inputStyles,
  selectWrapStyles,
  selectStyles,
  formButtonStyles,
} from '../styles';
import { Button, Heading } from './ui';
import { SiteContext } from '../context/SiteContext';

const StyledCallbackForm = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${({ isCallbackOpen }) => (isCallbackOpen ? 'block' : 'none')};
  background-color: rgba(0, 0, 0, 50%);
  z-index: ${zIndexLayers.fifth};
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  transform: translate(0, -50%);

  ${minBreakpointQuery.medium`
    width: 780px;
  `}

  &:before {
    content: '';
    background-color: black;
  }
`;

const StyledClose = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 0;
  padding: 15px 19px;
  background-color: ${brandColours.secondary};
  cursor: pointer;

  ${minBreakpointQuery.medium`
    padding: 20px 24px;
  `}

  &:hover {
    color: ${brandColours.tertiary};
  }

  &:before {
    content: '✕';
    color: ${brandColours.primary};
    ${fontSize(24)};
    font-weight: ${fontWeights.bold};
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledOuter = styled.div`
  background-color: ${brandColours.quaternary};
  padding: 50px 30px;

  ${minBreakpointQuery.medium`
    padding: 70px 100px;
  `}
`;

const StyledHeading = styled(Heading)`
  text-align: center;

  ${maxBreakpointQuery.medium`
    margin-top: 20px;
  `}
`;

const StyledInner = styled.form`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  ${minBreakpointQuery.medium`
    padding-top: 40px;
  `}
`;

const StyledLabel = styled.label`
  ${({ honeypot }) => labelStyles(honeypot)}
`;

const StyledLabelFull = styled.label`
  ${fullLabelStyles()};
`;

const StyledLabelText = styled.span``;

const StyledInput = styled.input`
  ${inputStyles()};
`;

const StyledSelectWrap = styled.div`
  ${selectWrapStyles()};
`;

const StyledSelect = styled.select`
  ${selectStyles()};
`;

const StyledSubmit = styled(Button)`
  ${formButtonStyles()};
`;

const CallbackForm = () => {
  const { isCallbackOpen, toggleCallbackOpen } = useContext(SiteContext);

  return (
    <StyledCallbackForm isCallbackOpen={isCallbackOpen}>
      <StyledContainer>
        <StyledClose onClick={toggleCallbackOpen}>
          <span>Close</span>
        </StyledClose>
        <StyledOuter>
          <StyledHeading>Request a Callback</StyledHeading>
          <StyledInner
            id="callback-form"
            name="callback-form"
            method="POST"
            action="/thanks/"
            netlify-honeypot="question"
            data-netlify="true"
            onSubmit={() => {
              trackCustomEvent({
                category: 'Callback Form',
                action: 'Submit',
              });
            }}
          >
            <input type="hidden" name="form-name" value="callback-form" />
            <StyledLabelFull>
              <StyledLabelText>Your Name</StyledLabelText>
              <StyledInput type="text" name="name" required />
            </StyledLabelFull>
            <StyledLabel>
              <StyledLabelText>Phone Number</StyledLabelText>
              <StyledInput type="text" name="phone" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Email Address</StyledLabelText>
              <StyledInput type="email" name="email" required />
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Best time to call?</StyledLabelText>
              <StyledSelectWrap>
                <StyledSelect
                  type="select"
                  name="call-time"
                  defaultValue=""
                  required
                >
                  <option value="">Please Select</option>
                  <option value="9-11">Between 9:00 &amp; 11:00</option>
                  <option value="11-1">Between 11:00 &amp; 13:00</option>
                  <option value="13-15">Between 13:00 &amp; 15:00</option>
                  <option value="15-17">Between 15:00 &amp; 17:00</option>
                </StyledSelect>
              </StyledSelectWrap>
            </StyledLabel>
            <StyledLabel>
              <StyledLabelText>Job Location</StyledLabelText>
              <StyledSelectWrap>
                <StyledSelect
                  type="select"
                  name="job-location"
                  defaultValue=""
                  required
                >
                  <option value="">Please Select</option>
                  <option value="east-midlands">East Midlands</option>
                  <option value="east-england">East of England</option>
                  <option value="london">London</option>
                  <option value="north-east">North East</option>
                  <option value="north-wales">North Wales</option>
                  <option value="north-west">North West</option>
                  <option value="scotland">Scotland</option>
                  <option value="south-east">South East</option>
                  <option value="south-wales">South Wales</option>
                  <option value="south-west">South West</option>
                  <option value="west-midlands">West Midlands</option>
                  <option value="yorkshire-humber">
                    Yorkshire and the Humber
                  </option>
                </StyledSelect>
              </StyledSelectWrap>
            </StyledLabel>
            <StyledLabel honeypot={true}>
              <StyledLabelText>Question</StyledLabelText>
              <StyledInput type="text" name="question" />
            </StyledLabel>
            <StyledSubmit type="submit">Request a Callback</StyledSubmit>
          </StyledInner>
        </StyledOuter>
      </StyledContainer>
    </StyledCallbackForm>
  );
};

export default CallbackForm;
