import React from 'react';
import styled, { css } from 'styled-components';
import {
  maxBreakpointQuery,
  minBreakpointQuery,
  brandColours,
  zIndexLayers,
  standardColours,
} from '../styles';
import { Container } from './ui';
import Logo from './Logo';
import Navigation from './Navigation';

const StyledHeader = styled.header`
  position: relative;
  z-index: ${zIndexLayers.fourth};
  background-color: ${brandColours.primary};
  border-bottom: 1px solid ${brandColours.quinary};

  ${({ isEquipmentSingle }) => {
    if (!isEquipmentSingle) {
      return css`
        ${minBreakpointQuery.mlarge`
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          background-color: ${standardColours.transparent};
        `}
      `;
    }
  }};
`;

const StyledContainer = styled(Container)`
  ${maxBreakpointQuery.mlarge`
    padding-right: 0;
    padding-left: 0;
  `}
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${minBreakpointQuery.mlarge`
    align-items: center;
  `}
`;

const StyledLogo = styled(Logo)`
  ${maxBreakpointQuery.mlarge`
    padding: 15px 30px;
  `}
`;

const Header = ({ isEquipmentSingle }) => (
  <StyledHeader isEquipmentSingle={isEquipmentSingle}>
    <StyledContainer>
      <StyledInner>
        <StyledLogo />
        <Navigation />
      </StyledInner>
    </StyledContainer>
  </StyledHeader>
);

export default Header;
