import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  zIndexLayers,
  standardTransition,
} from '../styles';
import { Link } from './ui';
import CallbackButton from './CallbackButton';

const itemStyles = () => {
  return css`
    ${maxBreakpointQuery.mlarge`
      margin-bottom: 10px;
      border-bottom: 1px solid ${brandColours.quinary};
    `}

    ${minBreakpointQuery.mlarge`
      display: inline-block;
      margin-right: 16px;
    `}

    ${minBreakpointQuery.large`
      margin-right: 26px;
    `}
  `;
};

const linkStyles = () => {
  return css`
    position: relative;
    display: block;
    color: ${standardColours.white};
    font-weight: ${fontWeights.medium};
    transition: ${standardTransition('color')};

    ${maxBreakpointQuery.mlarge`
      padding: 15px 30px;
    `}

    ${minBreakpointQuery.mlarge`
      padding: 28px 4px;
    `}

    &:after {
      ${minBreakpointQuery.mlarge`
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background-color: ${brandColours.secondary};
        opacity: 0;
        transition: ${standardTransition('opacity')};
      `}
    }

    &:hover,
    &.current-page {
      color: ${brandColours.secondary};

      &:after {
        ${minBreakpointQuery.mlarge`
          opacity: 1;
        `}
      }
    }
  `;
};

const StyledNavigation = styled.nav``;

const StyledToggle = styled.button`
  ${maxBreakpointQuery.mlarge`
    position: relative;
    margin: 0;
    padding: 8px 0;
    height: 100%;
    background-color: ${brandColours.primary};
    border: none;
    border-left: 1px solid ${brandColours.quinary};
    cursor: pointer;
  `}

  ${minBreakpointQuery.mlarge`
    display: none;
  `}

  span {
    ${maxBreakpointQuery.mlarge`
      display: block;
      height: 2px;
      width: 22px;
      margin: 6px 22px;
      background-color: ${standardColours.white};
    `}
  }
`;

const StyledList = styled.ul`
  ${maxBreakpointQuery.mlarge`
    position: absolute;
    right: 0;
    left: 0;
    z-index: ${zIndexLayers.fourth};
    display: ${({ displayNav }) => (displayNav ? 'block' : 'none')};
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: ${brandColours.primary}
  `}

  ${minBreakpointQuery.mlarge`
    display: inline-block;
    vertical-align: middle;
  `}
`;

const StyledItem = styled.li`
  ${itemStyles()};
`;

const StyledLink = styled(Link)`
  ${linkStyles()};
`;

const StyledButton = styled(CallbackButton)`
  display: none;
  padding: 28px 18px;
  color: ${brandColours.primary};
  background-color: ${brandColours.secondary};

  ${minBreakpointQuery.mlarge`
    display: inline-block;
  `}

  ${minBreakpointQuery.large`
    padding-right: 28px;
    padding-left: 28px;
  `}

  &:hover {
    color: ${brandColours.tertiary};
  }
`;

const Navigation = () => {
  const {
    datoCmsHeader: { navigation },
  } = useStaticQuery(graphql`
    query NavigationQuery {
      datoCmsHeader {
        navigation {
          id
          text
          page {
            ...LinkFragment
          }
        }
      }
    }
  `);

  const [displayNav, setDisplayNav] = useState(false);

  return (
    <StyledNavigation>
      <StyledToggle
        aria-label="Open / Close Navigation"
        onClick={() => setDisplayNav(!displayNav)}
      >
        <span />
        <span />
        <span />
      </StyledToggle>
      <StyledList displayNav={displayNav}>
        {navigation.map(({ id, text, page }) => (
          <StyledItem key={id}>
            <StyledLink
              to={page.slug}
              linkData={page}
              activeClassName="current-page"
            >
              {text}
            </StyledLink>
          </StyledItem>
        ))}
      </StyledList>
      <StyledButton />
    </StyledNavigation>
  );
};

export default Navigation;
