import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  zIndexLayers,
  standardTransition,
} from '../../styles';

const StyledSlider = styled(SlickSlider)`
  position: relative;

  .slick-arrow {
    position: absolute;
    top: 50%;
    overflow: hidden;
    z-index: ${zIndexLayers.first};
    margin-top: -15px;
    ${fontSize(0)};
    background: ${standardColours.white};
    border: 1px solid ${brandColours.primary};
    border-radius: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.small`
      margin-top: -20px;
    `}

    ${minBreakpointQuery.large`
      margin-top: -25px;
    `}

    &:after {
      content: '';
      display: block;
      height: 12px;
      width: 12px;
      border: 2px solid ${brandColours.primary};
      transform: rotate(45deg);
      transition: ${standardTransition('border-color')};

      ${minBreakpointQuery.small`
        height: 20px;
        width: 20px;
      `}
    }

    &:hover {
      background-color: ${brandColours.primary};

      &:after {
        border-color: ${standardColours.white};
      }
    }

    &.slick-prev {
      left: 0;
      padding: 14px 11px 14px 17px;

      ${minBreakpointQuery.small`
        left: -10px;
        padding: 20px 15px 20px 25px;
      `}

      ${minBreakpointQuery.large`
        left: -40px;
        padding: 30px 25px 30px 35px;
      `}

      &:after {
        border-top: 0;
        border-right: 0;
      }
    }

    &.slick-next {
      right: 0;
      padding: 14px 17px 14px 11px;

      ${minBreakpointQuery.small`
        right: -10px;
        padding: 20px 25px 20px 15px;
      `}

      ${minBreakpointQuery.large`
        right: -40px;
        padding: 30px 35px 30px 25px;
      `}

      &:after {
        border-bottom: 0;
        border-left: 0;
      }
    }
  }
`;

export const Slider = ({ sliderOptions, children, ...props }) => (
  <StyledSlider {...sliderOptions} {...props}>
    {children}
  </StyledSlider>
);
