import React, { useContext } from 'react';
import { Button } from './ui';
import { SiteContext } from '../context/SiteContext';

const CallbackButton = ({ ...props }) => {
  const { toggleCallbackOpen } = useContext(SiteContext);

  return (
    <Button onClick={toggleCallbackOpen} {...props}>
      Request a callback
    </Button>
  );
};

export default CallbackButton;
