import { css } from 'styled-components';
import {
  brandColours,
  brandFonts,
  minBreakpointQuery,
  fontSize,
  visuallyHidden,
} from '../../styles';

export const labelStyles = honeypot => {
  if (!honeypot) {
    return css`
      display: block;
      margin-bottom: 20px;
      grid-column: 1 / 3;

      ${minBreakpointQuery.medium`
        grid-column: auto;
      `}
    `;
  } else {
    return css`
      ${visuallyHidden()};
    `;
  }
};

export const fullLabelStyles = () => {
  return css`
    display: block;
    margin-bottom: 20px;
    grid-column: 1 / 3;
  `;
};

export const inputStyles = () => {
  return css`
    width: 100%;
    height: 50px;
    border: 1px solid ${brandColours.primary};
    padding: 0 20px;
    margin-top: 10px;
    background-color: ${brandColours.quaternary};
    font-family: ${brandFonts.primary};

    ${minBreakpointQuery.medium`
      height: 60px;
    `}

    ${minBreakpointQuery.mlarge`
      ${fontSize(16)};
      height: 70px;
    `}
  `;
};

export const selectWrapStyles = () => {
  return css`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 55%;
      right: 20px;
      height: 10px;
      width: 10px;
      border: 1px solid ${brandColours.primary};
      border-top: 0;
      border-left: 0;
      pointer-events: none;
      transform: translateY(-6px) rotate(45deg);
    }
  `;
};

export const selectStyles = () => {
  return css`
    width: 100%;
    appearance: none;
    height: 50px;
    border: 1px solid ${brandColours.primary};
    padding: 0 20px;
    margin-top: 10px;
    background-color: ${brandColours.quaternary};
    font-family: ${brandFonts.primary};

    ${minBreakpointQuery.medium`
      height: 60px;
    `}

    ${minBreakpointQuery.mlarge`
      ${fontSize(16)};
      height: 70px;
    `}
  `;
};

export const textAreaStyles = () => {
  return css`
    width: 100%;
    min-height: 100px;
    max-width: 100%;
    padding: 26px 26px;
    border: 1px solid ${brandColours.primary};
    margin-top: 10px;
    background-color: ${brandColours.quaternary};

    ${minBreakpointQuery.mlarge`
      min-height: 160px;
      ${fontSize(16)};
    `}
  `;
};

export const formButtonStyles = () => {
  return css`
    grid-column: 1 / 3;
    margin-top: 10px;
  `;
};
