import React from 'react';
import { Link } from './Link';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  standardTransition,
} from '../../styles';

const StyledLineLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  white-space: nowrap;
  transition: ${standardTransition('color')};

  &:before {
    content: '';
    display: inline-block;
    margin-right: 12px;
    height: 3px;
    width: 40px;
    background-color: ${brandColours.primary};
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.small`
      margin-right: 14px;
      width: 60px;
    `}

    ${minBreakpointQuery.large`
      margin-right: 16px;
      width: 80px;
    `}
  }

  &:hover {
    color: ${brandColours.primary};

    &:before {
      background-color: ${brandColours.secondary};
    }
  }
`;

export const LineLink = ({ to, children, ...props }) => (
  <StyledLineLink to={to} {...props}>
    {children}
  </StyledLineLink>
);
