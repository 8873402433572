import React, { useRef, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import styled from 'styled-components';

const StyledVideo = styled.div`
  display: inline-block;
  width: 100%;
`;

const StyledVideoElement = styled.video`
  height: auto;
  width: 100%;
  max-width: 100%;
`;

const StyledExternalVideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${({ ratio }) => `${ratio}%`};
`;

const StyledExternalVideoElement = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Video = ({
  data,
  controls = true,
  muted = true,
  autoPlay = false,
  playsInline = true,
  ...props
}) => {
  const videoEl = useRef(null);
  const videoSourceEl = useRef(null);

  const playVideo = () => {
    if (autoPlay) {
      videoSourceEl.current.src = videoSourceEl.current.dataset.src;
      videoEl.current.load();
    }
  };

  useEffect(() => {
    playVideo();
  }, [data]);

  if (data.provider) {
    return (
      <StyledVideo {...props}>
        <StyledExternalVideoContainer
          ratio={
            data.height && data.width ? (data.height / data.width) * 100 : 56.25
          }
        >
          <StyledExternalVideoElement
            src={
              data.provider === 'youtube'
                ? `https://www.youtube.com/embed/${data.providerUid}`
                : data.provider === 'vimeo'
                ? `https://player.vimeo.com/video/${data.providerUid}`
                : `https://www.facebook.com/plugins/data.php?href=${data.url}`
            }
          ></StyledExternalVideoElement>
        </StyledExternalVideoContainer>
      </StyledVideo>
    );
  } else {
    return (
      <InView triggerOnce={true} onChange={() => playVideo()} {...props}>
        <StyledVideo>
          <StyledVideoElement
            ref={videoEl}
            {...(!autoPlay && { preload: 'none' })}
            {...(!autoPlay && { poster: data.video.thumbnailUrl })}
            controls={controls}
            muted={muted}
            autoPlay={autoPlay}
            playsInline={playsInline}
          >
            <source
              ref={videoSourceEl}
              {...(autoPlay
                ? { 'data-src': data.video.mp4Url }
                : { src: data.video.mp4Url })}
              type="video/mp4"
            />
          </StyledVideoElement>
        </StyledVideo>
      </InView>
    );
  }
};
