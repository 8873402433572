export const standardColours = {
  black: '#000',
  white: '#FFF',
  transparent: 'rgba(255, 255, 255, 0)',
};

export const brandColours = {
  primary: '#004024', // dark green
  secondary: '#D4D819', // lime green
  tertiary: '#141414', // dark grey
  quaternary: '#F2F7F6', // light green
  quinary: '#00311C', // darker green
  senary: '#326751', // green
  septenary: '#9E9E9E', // light grey
};
