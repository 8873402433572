export const buildUrl = (slug, linkData) => {
  let url = '/';

  if (linkData) {
    if (linkData.treeParent) {
      if (
        linkData.treeParent.treeParent &&
        linkData.treeParent.treeParent.treeParent
      ) {
        url += `${linkData.treeParent.treeParent.treeParent.slug}/`;
      }

      if (linkData.treeParent.treeParent) {
        url += `${linkData.treeParent.treeParent.slug}/`;
      }

      url += `${linkData.treeParent.slug}/`;
    } else if (linkData.internal && linkData.internal.type) {
      switch (linkData.internal.type) {
        case 'DatoCmsEquipmentCategory':
        case 'DatoCmsEquipment':
          url += 'equipment/';
          break;
        case 'DatoCmsTeam':
          url += 'team/';
          break;
        case 'DatoCmsProjectCategory':
        case 'DatoCmsProject':
          url += 'projects/';
          break;
        case 'DatoCmsNews':
          url += 'news/';
          break;
        case 'DatoCmsPressRelease':
          url += 'press-releases/';
          break;
        default:
          url += '';
      }
    }
  }

  url += `${slug ? slug.replace(/^\/|\/$/g, '') : ''}/`;

  return url;
};
