import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';

const StyledCallCta = styled.p`
  display: inline-block;
  color: ${({ alt }) => (alt ? standardColours.white : brandColours.tertiary)};
  line-height: 1.1;
  white-space: nowrap;
  border-bottom: 3px solid
    ${({ alt }) => (alt ? brandColours.secondary : brandColours.primary)};

  span {
    display: block;
    margin: 8px 0;
    color: ${({ alt }) => (alt ? standardColours.white : brandColours.primary)};
    font-weight: ${fontWeights.bold};
    ${fontSize(22)};
    letter-spacing: 0.6px;

    ${minBreakpointQuery.large`
      margin-top: 12px;
      margin-bottom: 12px;
    `}
  }
`;

const CallCta = ({ alt }) => {
  const {
    datoCmsContactInformation: { phoneNumber },
  } = useStaticQuery(graphql`
    query CallCtaQuery {
      datoCmsContactInformation {
        phoneNumber
      }
    }
  `);

  return (
    <StyledCallCta alt={alt}>
      or Call us on: <span>{phoneNumber}</span>
    </StyledCallCta>
  );
};

export default CallCta;
