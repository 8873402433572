import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  brandColours,
  brandFonts,
  standardColours,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionPaddings,
  standardTransition,
} from '../styles';
import { Container, Link, Svg } from './ui';
import Logo from './Logo';
import linkedinIcon from '../images/linkedin-icon.svg';
import twitterIcon from '../images/twitter-icon.svg';
import facebookIcon from '../images/facebook-icon.svg';
import instagramIcon from '../images/instagram-icon.svg';

const StyledFooter = styled.footer`
  background-color: ${brandColours.primary};
  color: ${standardColours.white};
`;

const StyledSectionTop = styled.div`
  ${sectionPaddings('70px', '170px')};
`;

const StyledSectionTopInner = styled.div`
  ${minBreakpointQuery.small`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: 3fr 1fr repeat(2, 2fr);
    grid-column-gap: 20px;
  `}
`;

const StyledLogoSocials = styled.div`
  ${maxBreakpointQuery.medium`
    grid-column: 1/3;
  `}
`;

const StyledSocialLinks = styled.div`
  margin: 20px 0;

  ${minBreakpointQuery.small`
    margin-top: 30px;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`;

const StyledSocialLink = styled.a`
  display: inline-block;
  margin: 10px 20px 10px 0;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    svg {
      fill: ${brandColours.secondary};
    }
  }
`;

const StyledSocialIcon = styled(Svg)`
  height: 26px;
  width: 26px;
  fill: ${standardColours.white};
  transition: ${standardTransition('fill')};
`;

const StyledNav = styled.nav`
  ${maxBreakpointQuery.medium`
    margin: 30px 0;
  `}
`;

const StyledHeading = styled.h3`
  ${fontSize(17)};
  margin-bottom: 20px;

  ${minBreakpointQuery.large`
    ${fontSize(18)};
  `}
`;

const StyledNavItem = styled.li`
  margin: 10px 0;
  ${fontSize(15)};

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledContact = styled.div``;

const StyledAddress = styled.p`
  ${fontSize(15)};
  white-space: pre-line;
  line-height: 1.75;

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledContactPhone = styled.div`
  margin: 20px 0;
`;

const StyledPhoneHeading = styled.h4`
  ${fontSize(17)};
  font-weight: ${fontWeights.regular};
  line-height: 2;

  ${minBreakpointQuery.large`
    ${fontSize(18)};
  `}
`;

const StyledPhone = styled.p`
  ${fontSize(23)};
  line-height: 1.4;
  color: ${brandColours.secondary};

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `}
`;

const StyledSectionBottom = styled.div`
  padding: 55px 0;
  border-top: 2px solid #00331d;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;

  ${minBreakpointQuery.medium`
    flex-direction: row;
    justify-content: space-between;
  `}

  &:before {
    content: '';
    width: 1px;
    height: 55px;
    background-color: ${brandColours.secondary};
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

const StyledCopyright = styled.p`
  order: 2;
  margin: 30px 0px;
  ${fontSize(15)};

  ${minBreakpointQuery.medium`
    order: 1;
    margin: 0;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledBackToTop = styled.button`
  background: none;
  border: none;
  color: ${standardColours.white};
  padding: 0;
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.bold};
  ${fontSize(14)};
  cursor: pointer;
  order: 1;

  ${minBreakpointQuery.medium`
    order: 2;
  `}
`;

const StyledSignitureText = styled.p`
  order: 3;
  ${fontSize(15)};

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledSignitureLink = styled(Link)`
  text-decoration: underline;
`;

const Footer = () => {
  const {
    datoCmsSite: {
      globalSeo: { siteName },
    },
    datoCmsFooter: { policies, links },
    datoCmsContactInformation: {
      address,
      phoneNumber,
      linkedinProfile,
      twitterProfile,
      facebookProfile,
      instagramProfile,
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsSite {
        globalSeo {
          siteName
        }
      }
      datoCmsFooter {
        policies {
          text
          page {
            ...LinkFragment
          }
        }
        links {
          text
          page {
            ...LinkFragment
          }
        }
      }
      datoCmsContactInformation {
        address
        phoneNumber
        linkedinProfile
        twitterProfile
        facebookProfile
        instagramProfile
      }
    }
  `);

  return (
    <StyledFooter>
      <Container>
        <StyledSectionTop>
          <StyledSectionTopInner>
            <StyledLogoSocials>
              <Logo />
              {(linkedinProfile ||
                twitterProfile ||
                facebookProfile ||
                instagramProfile) && (
                <StyledSocialLinks>
                  {linkedinProfile && (
                    <StyledSocialLink
                      href={linkedinProfile}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                    >
                      <StyledSocialIcon
                        image={linkedinIcon}
                        title={`Linkedin`}
                      />
                    </StyledSocialLink>
                  )}
                  {twitterProfile && (
                    <StyledSocialLink
                      href={twitterProfile}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                    >
                      <StyledSocialIcon image={twitterIcon} title={`Twitter`} />
                    </StyledSocialLink>
                  )}
                  {facebookProfile && (
                    <StyledSocialLink
                      href={facebookProfile}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                    >
                      <StyledSocialIcon
                        image={facebookIcon}
                        title={`Facebook`}
                      />
                    </StyledSocialLink>
                  )}
                  {instagramProfile && (
                    <StyledSocialLink
                      href={instagramProfile}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                    >
                      <StyledSocialIcon
                        image={instagramIcon}
                        title={`Instagram`}
                      />
                    </StyledSocialLink>
                  )}
                </StyledSocialLinks>
              )}
            </StyledLogoSocials>
            <StyledNav>
              <StyledHeading>Links</StyledHeading>
              <ul>
                {links.map(({ text, page }) => (
                  <StyledNavItem>
                    <Link to={page.slug} linkData={page} title={text}>
                      {text}
                    </Link>
                  </StyledNavItem>
                ))}
              </ul>
            </StyledNav>
            <StyledNav>
              <StyledHeading>Policies</StyledHeading>
              <ul>
                {policies.map(({ text, page }) => (
                  <StyledNavItem>
                    <Link to={page.slug} linkData={page} title={text}>
                      {text}
                    </Link>
                  </StyledNavItem>
                ))}
              </ul>
            </StyledNav>
            <StyledContact>
              <StyledHeading>Contact us</StyledHeading>
              <StyledAddress>{address}</StyledAddress>
              <StyledContactPhone>
                <StyledPhoneHeading>Talk to our team:</StyledPhoneHeading>
                <StyledPhone>{phoneNumber}</StyledPhone>
              </StyledContactPhone>
            </StyledContact>
          </StyledSectionTopInner>
        </StyledSectionTop>
        <StyledSectionBottom>
          <StyledCopyright>
            Copyright&copy; {new Date().getFullYear()} {siteName}
          </StyledCopyright>
          <StyledBackToTop
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            Back to Top
          </StyledBackToTop>
          <StyledSignitureText>
            Website by{' '}
            <StyledSignitureLink
              href="https://www.attachdigital.co.uk"
              target={`_blank`}
              rel={`noopener`}
            >
              Attach Digital
            </StyledSignitureLink>
          </StyledSignitureText>
        </StyledSectionBottom>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
