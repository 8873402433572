import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fontWeights,
  standardTransition,
} from '../../styles';
import { Link } from './Link';

const commonButtonStyles = () => {
  return css`
    display: inline-block;
    padding: 20px 28px;
    color: ${standardColours.white};
    ${fontSize(16)};
    font-weight: ${fontWeights.bold};
    line-height: 1.3;
    text-align: center;
    background-color: ${brandColours.primary};
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.large`
      padding: 24px 32px;
    `}

    &:hover {
      color: ${brandColours.primary};
      background-color: ${brandColours.secondary};
    }
  `;
};

const StyledButton = styled.button`
  ${commonButtonStyles()};
  font-family: ${brandFonts.primary};
  border: none;
  cursor: pointer;
`;

const StyledButtonLinkInternal = styled(Link)`
  ${commonButtonStyles()};
`;

const StyledButtonLinkExternal = styled.a`
  ${commonButtonStyles()};
`;

export const Button = ({ alt, children, ...props }) => {
  const ButtonComponent = props.to
    ? StyledButtonLinkInternal
    : props.href
    ? StyledButtonLinkExternal
    : StyledButton;

  return (
    <ButtonComponent alt={alt} {...props}>
      {children}
    </ButtonComponent>
  );
};
