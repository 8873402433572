import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { buildUrl } from '../../utils';

const StyledLink = styled(GatsbyLink)`
  display: inline-block;
`;

export const Link = ({ to, linkData, children, ...props }) => (
  <StyledLink to={to === '/' ? to : buildUrl(to, linkData)} {...props}>
    {children}
  </StyledLink>
);

export const LinkFragment = graphql`
  fragment LinkFragment on Node {
    ... on DatoCmsHome {
      slug
    }

    ... on DatoCmsPage {
      slug
      treeParent {
        slug
        treeParent {
          slug
          treeParent {
            slug
          }
        }
      }
    }

    ... on DatoCmsEquipmentArchive {
      slug
    }

    ... on DatoCmsEquipmentCategory {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsEquipment {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsTeamArchive {
      slug
    }

    ... on DatoCmsTeam {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsProjectsArchive {
      slug
    }

    ... on DatoCmsProjectCategory {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsProject {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsNewsArchive {
      slug
    }

    ... on DatoCmsNews {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsPressReleasesArchive {
      slug
    }

    ... on DatoCmsPressRelease {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsQuote {
      slug
    }
  }
`;
